import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"
import Layout from '../components/Layout'
// import Img from 'gatsby-image'

//images
import miodPszczeli from '../images/miod-pszczeli.jpg'
import produktyPszczele from '../images/produkty-pszczele.jpg'
import mikroklimatUla from '../images/mikroklimat-ula.jpg'
import miodyOdmianowe from '../images/miody-odmianowe.jpg'

export const query = graphql`
query siteMeta {
  site {
    siteMetadata {
      text
      title
    }
  }
  miodPszczeli: file(relativePath: {eq: "images/miod-pszczeli.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 460) {
        src
        srcWebp
        srcSetWebp
        sizes
        aspectRatio
      }
    }
  }
  produktyPszczele: file(relativePath: {eq: "images/produkty-pszczele.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 460) {
        src
        srcWebp
        srcSetWebp
        sizes
        aspectRatio
      }
    }
  }
  miodyOdmianowe: file(relativePath: {eq: "images/miody-odmianowe.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 460) {
        src
        srcWebp
        srcSetWebp
        sizes
        aspectRatio
      }
    }
  }
  mikroklimatUla: file(relativePath: {eq: "images/mikroklimat-ula.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 460) {
        src
        srcWebp
        srcSetWebp
        sizes
        aspectRatio
      }
    }
  }
}`

const IndexPage = (props) => (
  <Layout>
    <PageTitle>Witaj na stronie Bonika</PageTitle>
    <LinkStyled to="/miod-pszczeli">
      <ImageWrapper>
        <ImgStyled src={miodPszczeli} alt="Miód pszczeli" />
      </ImageWrapper>
      <TextWrapper>
        <SectionTitle>Miód Pszczeli</SectionTitle>
        <p>Jest to naturalny produkt wytwarzany z nektaru kwiatowego lub spadzi. Nektar z kwiatów bądź spadż zbierana jest przez pszczoły, a następnie przerabiane w ulu przez pszczoły ulowe. Pszczoły ulowe to nieskażone młode robotnice, które jeszcze nie wylatują z ula przerabiając nektar dodają do niego wydzielinę swoich gruczołów nazywaną enzymami. Dzięki pracy pszczół dojrzewa i jest składany do komórek plastra pszczelego...</p>
      </TextWrapper>
    </LinkStyled>
    <LinkStyled to="/produkty-pszczele">
      <TextWrapper>
        <SectionTitle>Produkty Pszczele</SectionTitle>
        <p>Pszczoły dostarczają wielu surowców i produktów, które wykorzystywane są w różnych dziedzinach naszego życia. Najbardziej znanym produktem pszczelim jest miód ,który jest pozyskiwany przez człowieka od tysięcy lat. Produkty pszczele należy podzielić na te , które są wytworem organizmu pszczół robotnic i wytwarzane przez pszczoły z surowców zebranych w środowisku....</p>
      </TextWrapper>
      <ImageWrapper>
        <ImgStyled src={produktyPszczele} alt="Produkty pszczele" right/>
      </ImageWrapper>
    </LinkStyled>
    <LinkStyled to="/mikroklimat-ula">
      <ImageWrapper>
        <ImgStyled src={mikroklimatUla} alt="Mikroklimat ula" />
      </ImageWrapper>
      <TextWrapper>
        <SectionTitle>Mikroklimat Ula</SectionTitle>
        <p>W ostatnim okresie czasu można spotkać coraz więcej informacji w opracowaniach naukowych z dziedziny apiterapii na temat leczniczego działania mikroklimatu ula. Mikroklimat ula ma działanie lecznicze. Wewnątrz ula jest ciepło i temperatura waha się od 25-28 C, panuje tam wysoka wilgotność ,a mimo to warunki te nie pozwalają rozwijać się drobnoustrojom...</p>
      </TextWrapper>
    </LinkStyled>
    <LinkStyled to="/miody-odmianowe">
      <TextWrapper>
        <SectionTitle>Miody Odmianowe</SectionTitle>
        <p>Zwane też miodami gatunkowymi , to miody wytworzone przez pszczoły o przewadze nektaru jednej rośliny. Powstanie miodu odmianowego jest możliwe dlatego że pszczoły są wierne gatunkowi oblatywanej rośliny i tak długo będą zbierać z niej nektar do kiedy nie przestanie nektarować. Wszystkie miody mają wiele wspólnych cech i korzystnie wpływają na nasz organizm....</p>
      </TextWrapper>
      <ImageWrapper>
        <ImgStyled src={miodyOdmianowe} alt="Miody odmianowe" right />
      </ImageWrapper>
    </LinkStyled>
  </Layout>
)

const PageTitle = styled.h1`
  color: #640000;
  text-shadow: 1px 1px 0 #fff;
  display: inline-block;
  border-bottom: 2px solid;
  margin-bottom: 50px;
  padding-right: 50px;
`


const LinkStyled = styled(Link)`
  display: flex;
  flex-flow: row wrap;
  text-decoration: none;
  margin-top: 40px;
  
  &:nth-child(even){
    flex-direction: column-reverse;
    
    @media (min-width: 960px) {
      flex-direction: initial;
    }
  }
  @media (min-width: 960px) {
    margin-top: initial;
    flex-direction: initial;
  }
`
  
const ImageWrapper = styled.div`
  flex: 1 1 100%;
  
  @media (min-width: 960px) {
    flex: 1 1 50%;
  }
`

const TextWrapper = styled.div`
  flex: 1 1 100%;
  padding: 20px 0 20px 0;
  
  @media (min-width: 960px) {
    flex: 1 1 50%;
    padding: 20px;
  }

  p {
    color: #000;
    margin: 0;
    overflow: hidden;
  }
`

const SectionTitle = styled.h2`
  color: #640000;
  text-shadow: 1px 1px 0 #fff;
  margin: 0;
  padding: 0;


`

const ImgStyled = styled.img`
  display: block;
  margin: 0;
  float: none;
  max-width: 100%;
`

export default IndexPage
